/* eslint-disable max-lines */
import React, { useState, useEffect, useContext } from 'react';
import { Menu, Dropdown } from 'antd';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { Role, Status } from '../../../models/index';
import AdminContext, { MoreInfoStatus } from '../../../context/Admin/AdminContext';
import { modalFuncHandler, editRole, checkRole } from './Services/UserTabService';
import EditRole from './Popups/EditRole';
import ChangeGroup from './Popups/ChangeGroup';
import * as invitesTabStyles from '../../../styles/admin/InviteTab.module.scss';
import * as userTabStyles from '../../../styles/admin/UsersTab.module.scss';
import ConfirmPopup from './Popups/ConfirmPopup';
import MoreInfo from '../MoreInfo';
import TableComponent from '../../Shared/Table/TableComponent';
import { formatDate, hasInviteLimits, isBDMUser } from '../../../services/utils';
import { getGroupsByEnterpriseID, getUsersByEnterpriseID } from '../../GraphQL/Services';
import BuyMoreLicense from './Popups/BuyMoreLicense';
import licenseCount from './Services/GetLicenseData';

function UsersTabView({ identityProviderClient }) {
  const {
    userData, companyInfo, profileInfo,
    showMoreInfo, setShowMoreInfo, mapOfGroupsList,
  } = useContext(AdminContext);
  const [signedInUserDetails, setSignedInUserDetails] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [tableLoader, setTableLoader] = useState(false);
  const [modalObject, setModalObject] = useState({});
  const [changeGroupModal, setChangeGroupModal] = useState(false);
  const [editRoleModalVisible, setEditRoleModalVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [groupList, setGroupList] = useState([]);
  const [licenseLimit, setLicenseLimit] = useState({ showModal: false, exceeded: false });

  const listUsersData = async () => {
    setTableLoader(true);
    try {
      const allUsersList = await getUsersByEnterpriseID(companyInfo?.id);
      const users = allUsersList.filter((acceptedUser) => {
        if (acceptedUser?.roles?.includes('OWNER')) return false;
        if (acceptedUser?.deletedAt) return false;
        if (isBDMUser(acceptedUser.email)) return false;
        return acceptedUser;
      });
      const groupData = await getGroupsByEnterpriseID(companyInfo?.id);
      const filteredGroupData = groupData.filter((group) => !group.deletedAt);
      setTableData(users);
      setGroupList(filteredGroupData);
    } catch (error) {
      console.log(error);
    }
    setTableLoader(false);
  };

  async function checkLicenseLimit() {
    try {
      const licenseCountDetails = await licenseCount();
      const invitesLimited = hasInviteLimits(companyInfo?.subscription);
      const licenseExhausted = licenseCountDetails?.available <= 0;
      setLicenseLimit({
        showModal: false,
        exceeded: invitesLimited && licenseExhausted,
      });
    } catch (e) {
      console.log(e);
    }
  }

  async function toggleUserActivation(record) {
    if (!record.enabled) {
      if (licenseLimit?.exceeded) {
        setLicenseLimit((prev) => ({ ...prev, showModal: true }));
        return;
      }
    }
    setModalObject({
      isVisible: true,
      title: `${record?.enabled ? 'Deactivate' : 'Activate'} ${record?.name || 'user'}`,
      description: `Are you sure you want to ${record?.enabled ? 'deactivate' : 'activate'} the learner?`,
      buttonText: `${record?.enabled ? 'Deactivate' : 'Activate'}`,
      selectedUser: record,
      domIDs: {
        OK: `${record?.enabled ? 'admin-accounts-learners-deactivate-user-popup-deactivate' : 'admin-accounts-learners-activate-user-popup-activate'}`,
        Cancel: `${record?.enabled ? 'admin-accounts-learners-deactivate-user-popup-cancel' : 'admin-accounts-learners-activate-user-popup-cancel'}`,
      },
    });
  }

  useEffect(() => {
    if (userData?.sub) {
      setSignedInUserDetails(userData);
    }
  }, [userData]);

  useEffect(() => {
    if (companyInfo?.id) {
      listUsersData();
      checkLicenseLimit();
    }
  }, [companyInfo]);

  const menu = (record) => (
    <Menu>
      {
        (signedInUserDetails?.role === Role.ADMIN || signedInUserDetails?.role === Role.OWNER)
        && (
        <>
          <Menu.Item key={1}>
            <button
              id="admin-accounts-learners-change-group"
              type="button"
              onClick={() => {
                setSelectedUser(record);
                setChangeGroupModal(true);
              }}
            >
              Change Group
            </button>
          </Menu.Item>
          <Menu.Item key={2}>
            <button
              id="admin-accounts-learners-change-role"
              type="button"
              onClick={() => {
                setSelectedUser(record);
                setEditRoleModalVisible(true);
              }}
            >
              Change Role
            </button>
          </Menu.Item>
          <Menu.Item key={3}>
            <button
              id={record?.enabled ? 'admin-accounts-learners-deactivate-user' : 'admin-accounts-learners-activate-user'}
              type="button"
              onClick={() => toggleUserActivation(record)}
            >
              {record?.enabled ? 'Deactivate User' : 'Activate User'}
            </button>
          </Menu.Item>
        </>
        )
      }
      { record?.status !== Status.RESET_REQUIRED && (
      <Menu.Item key={4}>
        <button
          id="admin-accounts-learners-reset-login"
          type="button"
          onClick={() => {
            setModalObject({
              isVisible: true,
              title: `Reset login credentials for ${record?.name || 'user'}`,
              description: 'Once you reset the user credentials he/she will not be able to login with older credentials.',
              buttonText: 'Reset Credentials',
              selectedUser: record,
              domIDs: {
                OK: 'admin-accounts-learners-reset-login-credentials-popup-reset-credentials',
                Cancel: 'admin-accounts-learners-reset-login-credentials-popup-cancel',
              },
            });
          }}
        >
          Reset Login
        </button>
      </Menu.Item>
      )}
    </Menu>
  );

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      render: (name) => (
        <p className={userTabStyles.userName}>{name || ''}</p>
      ),
      width: 130,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      render: (email) => (
        <p className={userTabStyles.email}>{email || ''}</p>
      ),
      width: 130,
    },
    {
      title: 'Date Added',
      dataIndex: 'createdAt',
      defaultSortOrder: 'descend',
      sorter: (a, b) => dayjs(a.createdAt) - dayjs(b.createdAt),
      render: (date) => formatDate(date),
      width: 130,
    },
    {
      title: 'License Status',
      dataIndex: 'status',
      render: (text, record) => (
        <div className={userTabStyles.rowFlex}>
          <span style={{ width: '80px' }}>
            <p>
              {(record?.enabled || record?.member?.enabled) ? 'Active' : 'Inactive'}
            </p>
          </span>
          {
            record?.email !== signedInUserDetails?.email
            && (checkRole(record, signedInUserDetails))
              ? (
                <Dropdown overlay={() => menu(record)} trigger={['click']}>
                  <a href=" " className={`${userTabStyles.moreOptions} library-shaw-icon`}>
                    <i className="icon-more-3-dots" />
                  </a>
                </Dropdown>
              ) : null
          }
        </div>
      ),
      width: 130,
    },
  ];

  const headers = columns.map((column) => ({ label: column.title, key: column.dataIndex }));

  const filterByGroupHandler = (selectedGroups) => {
    if (selectedGroups?.length === groupList?.length) return tableData;
    const selectedGroupIds = groupList.filter((group) => selectedGroups.includes(group.groupName))
      .map((group) => group.id);

    const allSelectedGroupMembers = [];
    selectedGroupIds.forEach((groupId) => {
      const groupData = mapOfGroupsList?.get(groupId);
      const memberIds = groupData.memberInfo.filter((member) => !member?.deletedAt)
        .map((member) => member.memberID);
      allSelectedGroupMembers.push(...memberIds);
    });

    const filteredGroupMembers = [];
    tableData.forEach((member) => {
      if (allSelectedGroupMembers.includes(member.id) && member?.roles[0] !== Role.OWNER) {
        filteredGroupMembers.push(member);
      }
    });

    return filteredGroupMembers;
  };

  const closeMoreInfoBanner = () => {
    setShowMoreInfo(MoreInfoStatus.DEFAULT);
  };

  return (
    <div className={userTabStyles.userTabWrapper}>
      {showMoreInfo === MoreInfoStatus.SECONDARY && (
      <MoreInfo
        mainContent={false}
        text="<p>Look up individual learner status and edit permissions. Admins can change learner roles & groups, reset logins and deactivate learners. Managers can reset logins.</p>"
        hideBanner={closeMoreInfoBanner}
      />
      )}
      <p className={invitesTabStyles.title}>Learner Directory</p>
      <TableComponent
        globalSearchPlaceholder="Search by learner name, email"
        displayColumns={columns}
        allData={tableData}
        tableLoader={tableLoader}
        headers={headers}
        fileName="Users Report.csv"
        filters={
          {
            group: {
              name: 'Group',
              type: 'checkbox',
              options: groupList.map((group) => group.groupName),
              groupList,
              filterHandler: filterByGroupHandler,
            },
          }
        }
        domIDs={{
          Download: 'admin-accounts-learners-download',
        }}
      />
      <ConfirmPopup
        onOk={async () => {
          setTableLoader(true);
          setModalObject((prevState) => ({ ...prevState, isVisible: false }));
          await modalFuncHandler(modalObject, identityProviderClient, profileInfo, companyInfo);
          setModalObject({});
          listUsersData();
        }}
        onCancel={() => { setModalObject({}); }}
        modalObject={modalObject}
        domIDs={{
          OK: modalObject?.domIDs?.OK,
          Cancel: modalObject?.domIDs?.Cancel,
        }}
      />
      <ChangeGroup
        onCancel={() => {
          setSelectedUser(null);
          setChangeGroupModal(false);
        }}
        isModalVisible={changeGroupModal}
        identityProviderClient={identityProviderClient}
        selectedUser={selectedUser}
        domID="admin-accounts-learners-change-group-popup-save"
      />
      <EditRole
        isModalVisible={editRoleModalVisible}
        onOk={editRole}
        onCancel={() => {
          setEditRoleModalVisible(false);
          setSelectedUser(null);
          listUsersData();
        }}
        title="Edit Role"
        selectedUser={selectedUser}
        identityProviderClient={identityProviderClient}
        domID="admin-accounts-learners-change-role-popup-confirm"
      />
      <BuyMoreLicense
        profileInfo={profileInfo}
        isModalVisible={licenseLimit?.showModal}
        onCancel={() => { setLicenseLimit((prev) => ({ ...prev, showModal: false })); }}
      />
    </div>
  );
}
UsersTabView.propTypes = {
  identityProviderClient: PropTypes.objectOf(PropTypes.any).isRequired,
};
export default UsersTabView;
