/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState, useContext } from 'react';
import {
  Modal, Checkbox, Spin,
} from 'antd';
import PropTypes from 'prop-types';
import * as commonPopupStyles from '../../../../styles/admin/PopupCommon.module.scss';
import * as changeGroupStyles from '../../../../styles/admin/ChangeGroup.module.scss';
import AdminContext from '../../../../context/Admin/AdminContext';
import {
  addUsersToGroup, adminUpdateUser, updateGroupMember,
} from '../../../GraphQL/Services';
import { toaster } from '../../../../services/utils';

const ChangeGroup = ({
  isModalVisible, onCancel, selectedUser, identityProviderClient, domID,
}) => {
  const [groupList, setGroupList] = useState();
  const [userGroupIds, setUserGroupIds] = useState([]);
  const [userGroupIdsOnChange, setUserGroupIdsOnChange] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { mapOfGroupsList, fetchGroupsList } = useContext(AdminContext);

  const listGroupsHandler = () => {
    if (mapOfGroupsList !== null) {
      if (mapOfGroupsList?.size) {
        const listOfGroups = [];
        mapOfGroupsList.forEach((value, key) => {
          listOfGroups.push({ name: value?.groupName, id: key });
        });
        const sortedData = listOfGroups.sort((a, b) => (a.name || '').toString().localeCompare((b.name || '').toString()));
        setGroupList([...sortedData]);
      } else {
        setGroupList([]);
      }
    }
  };

  const getIdsOfGroupsWithUserMembership = (id) => {
    const userGroups = [];
    mapOfGroupsList?.forEach((value) => {
      const group = value?.memberInfo
        .filter((member) => member?.memberID === id && !member?.deletedAt)
        .map((currentMember) => currentMember?.groupID);
      userGroups.push(...group);
    });
    setUserGroupIds([...userGroups]);
    setUserGroupIdsOnChange([...userGroups]);
  };

  /**
   * remove user from cognito group
   *
   * @param {*} email
   * @param {*} groupName
   */
  const removeUserFromCognitoGroup = async (email, groupName) => {
    const groupObject = {
      groupName,
      user: email,
    };
    await removeUserFromCognitoGroup(identityProviderClient, groupObject);
  };

  const saveGroupHandler = async () => {
    setIsLoading(true);
    const promises = [];
    // add or remove user from group
    const addedGroups = userGroupIdsOnChange?.filter((groupId) => !userGroupIds.includes(groupId));
    if (addedGroups?.length) {
      addedGroups.forEach((groupId) => {
        promises.push(
          addUsersToGroup(
            groupId,
            selectedUser?.id,
            selectedUser?.sub,
            true,
            false,
          ),
        );
      });
    }

    const removedGroups = userGroupIds?.filter(
      (groupId) => !userGroupIdsOnChange.includes(groupId),
    );
    if (removedGroups?.length) {
      removedGroups.forEach((groupId) => {
        const groupMembers = mapOfGroupsList.get(groupId)?.memberInfo;
        const userGroupInfo = groupMembers
          .filter((member) => member?.memberID === selectedUser?.id && !member?.deletedAt)[0];
        promises.push(
          updateGroupMember(
            userGroupInfo?.id,
            userGroupInfo?._version,
            false,
            false,
            true,
          ),
        );
        // Checking if use was a manager in this group
        if (userGroupInfo?.isManager) {
        // If user was a manager, then checking if user is a manager in any group
          const groupsUserBelongsTo = [];
          let isManager = false;
          let roles = [];
          roles = [...selectedUser.roles];

          mapOfGroupsList?.forEach((group) => {
            const memberGroup = group?.memberInfo.filter(
              (member) => !member?.deletedAt
            && member?.memberID === selectedUser?.id
            && member?.groupID !== groupId,
            );

            if (memberGroup?.length) {
              groupsUserBelongsTo.push(memberGroup);
            }
          });

          // Checking if the user is a manager in any other group
          if (groupsUserBelongsTo?.length) {
            for (let i = 0; i < groupsUserBelongsTo.length; i += 1) {
              if (groupsUserBelongsTo[i].isManager) {
                isManager = true;
                break;
              }
            }
          }

          // If user is not a manager in any other group then remove him from the manager group
          if (!isManager) {
            removeUserFromCognitoGroup(selectedUser?.email, 'manager');
            if (!selectedUser?.roles?.includes('ADMIN')) {
              roles = [];
            }
          }

          promises.push(
            adminUpdateUser(selectedUser?.id, [...roles], selectedUser?._version),
          );
        }
      });
    }

    try {
      await Promise.all(promises);
      await fetchGroupsList(selectedUser?.enterpriseID);
    } catch (error) {
      console.log('promise err', error);
    }

    toaster('Groups changed successfully', 'success', 2);
    setIsLoading(false);
    onCancel();
  };

  const changeUserGroup = (e) => {
    // While the checked box is unchecked, checked key is returned as false
    if (!e.target.checked) {
      setUserGroupIdsOnChange((prevState) => {
        const filteredGroupIds = prevState.filter((groupId) => groupId !== e?.target?.value);
        return filteredGroupIds;
      });
    } else {
      setUserGroupIdsOnChange((prevState) => [...prevState, e.target.value]);
    }
  };

  useEffect(() => {
    if (isModalVisible
      && selectedUser
      && Object.keys(selectedUser).length !== 0) {
      listGroupsHandler();
      getIdsOfGroupsWithUserMembership(selectedUser?.id);
    }
  }, [isModalVisible, mapOfGroupsList, selectedUser]);

  return (
    <Modal
      visible={isModalVisible}
      onCancel={onCancel}
      width="85%"
      className="wrapper change-group-wrapper"
      footer={null}
    >
      <div className={changeGroupStyles.changeGroupWrapper}>
        <div className={commonPopupStyles.titleWrapper}>
          <p>Change group</p>
        </div>
        <div className={changeGroupStyles.contentWrapper}>
          <div className={changeGroupStyles.userWrapper}>
            <p>User</p>
            <input
              placeholder="Username"
              readOnly
              value={selectedUser?.name || selectedUser?.email?.split('@')[0]}
            />
          </div>
          <div className={changeGroupStyles.groupWrapper}>
            <p>Select groups</p>
            {
                groupList?.length > 0 && groupList.map((group) => (
                  <Checkbox
                    key={group?.id}
                    value={group?.id}
                    onChange={changeUserGroup}
                    checked={userGroupIdsOnChange?.includes(group.id)}
                  >
                    {group.name}
                  </Checkbox>
                ))
                }
            {
              typeof groupList === 'undefined' && <Spin className={changeGroupStyles.loader} />
            }
            <p
              className={changeGroupStyles.text}
            >
              You can always add more members from the Learner Directory later.
            </p>
            <div className={changeGroupStyles.wrapper}>
              <button
                id={domID}
                disabled={isLoading}
                type="button"
                className={changeGroupStyles.saveButton}
                onClick={saveGroupHandler}
              >
                Save
              </button>
              {
             isLoading
            && <Spin className={changeGroupStyles.loader} />
          }
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
ChangeGroup.propTypes = {
  isModalVisible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  selectedUser: PropTypes.objectOf(PropTypes.any).isRequired,
  identityProviderClient: PropTypes.objectOf(PropTypes.any),
  domID: PropTypes.string.isRequired,
};

ChangeGroup.defaultProps = {
  identityProviderClient: {},
};

export default ChangeGroup;
