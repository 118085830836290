// extracted by mini-css-extract-plugin
export var changeGroupWrapper = "ChangeGroup-module--changeGroupWrapper--2Ph6R";
export var contentWrapper = "ChangeGroup-module--contentWrapper--K5ilP";
export var userWrapper = "ChangeGroup-module--userWrapper--3G88V";
export var groupWrapper = "ChangeGroup-module--groupWrapper--1RWli";
export var list = "ChangeGroup-module--list--1PX1R";
export var container = "ChangeGroup-module--container--3owqw";
export var checkmark = "ChangeGroup-module--checkmark--2Jd8c";
export var text = "ChangeGroup-module--text--ijO50";
export var wrapper = "ChangeGroup-module--wrapper--35-J_";
export var saveButton = "ChangeGroup-module--saveButton--A1K0p";
export var loader = "ChangeGroup-module--loader--1i07O";