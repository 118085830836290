// extracted by mini-css-extract-plugin
export var rowFlex = "UsersTab-module--rowFlex--14BEK";
export var searchBoxWrapper = "UsersTab-module--searchBoxWrapper--3Ld7l";
export var userTabWrapper = "UsersTab-module--userTabWrapper--1Bq6a";
export var marginRight16 = "UsersTab-module--marginRight16--1-9k-";
export var searchDropdownWrapper = "UsersTab-module--searchDropdownWrapper--2t3mQ";
export var licenseBtn = "UsersTab-module--licenseBtn--8wocC";
export var userName = "UsersTab-module--userName--3ivnl";
export var email = "UsersTab-module--email--2b23Z";
export var admin = "UsersTab-module--admin--2mu9O";
export var manager = "UsersTab-module--manager--1UmSb";
export var table = "UsersTab-module--table--2MKIA";
export var moreOptions = "UsersTab-module--moreOptions--3JXOW";